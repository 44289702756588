 #vrm-public-print-container{
    .print-container {
        width: 1150px;
        position: relative;
        overflow: hidden;
        padding: 3px;
        margin: 0 auto;
        /* font-size: 16px; */
    }
     .print-container h2{
        text-align: center;
    }
     .print-container  .header-odd{
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        padding: 0px;
    }
    .print-container table{
        width: 100%;
        border-top:2px solid black;
        border-left:2px solid black;
        border-right:2px solid black;
    }
     .footer-table{
        border-bottom:2px solid black;
    }
    /* 表格明细线 */
     .print-container td{
        font-weight: 500;
        padding: 3px 5px;
        text-align: center;
        border: 1px solid black;
        
    }
     .print-container td:nth-child(1){
        width: 120px;
    }
     .print-container td:last-child{
        width: 150px;
    }
    .finance .item-table td:nth-child(1),.finance .material-table td:nth-child(1) {
        width: 65px !important;
    
    }
    .finance .item-table td:nth-child(2),.finance .material-table td:nth-child(2) {
        width: 200px !important;
        text-align: left;
        white-space: nowrap;
        overflow: hidden !important;
    }
    .finance .item-table td:nth-child(3),.finance .material-table td:nth-child(3) {
        width: 250px !important;
        text-align: left;
        white-space: nowrap;
        overflow: hidden !important;
    }
    .finance .item-table td:nth-child(4) {
        width: 120px !important;
    }
    .finance .material-table td:nth-child(4),.finance .material-table td:nth-child(5){
        width: 60px !important;
    }
    .finance .item-table td:nth-child(5),.finance .material-table td:nth-child(6) {
        width: 150px !important;
        text-align: right;
    }
    .finance .item-table td:nth-child(6),.finance .material-table td:nth-child(7) {
        width: 100px !important;
        /* text-align: right; */
    }
    .finance .item-table td:nth-child(7),.finance .material-table td:nth-child(8) {
        width: 150px !important;
        text-align: right;
    }
}