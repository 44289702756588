#scm-public-print-container{

    .print-container {
        width: 1150px;
        position: relative;
        overflow: hidden;
        padding: 0 3px;
        margin: 0 auto;
        color:black;
    }

    .print-container .title {
        margin: 60px 0 20px 0;
        text-align: center;
    }

    .print-container .header {
        padding: 0px;
        margin-bottom: 0;
        width: 100%;
    }

    .print-container .footer {
        padding: 0px;
    }

    .print-container .header li,
    .print-container .footer li {
        display: inline-block;
        width: 33.33%;
        white-space: nowrap;
    }

    .print-container .footer li.note {
        width: 89%;
        height: 46px;
        white-space: normal;
        position: relative;
        overflow: hidden;
    }

    .print-container .footer li.pagination {
        width: 10%;
        text-align: right;
    }

    .print-container .footer li.w20 {
        width: 20%;
    }
    .print-container .footer li.w16 {
        width: 16.5%;
    }

    .print-container .footer li.hidden {
        display: none;
    }

    .print-container .header li.w16 {
        width: 16.5%;
    }
    .print-container .header li.w20 {
        width: 20%;
    }
    .print-container .header li.w25 {
        width: 25%;
    }
    .print-container .header li.w30 {
        width: 30%;
    }
    .print-container .header li.w40 {
        width: 40%;
    }
    .print-container .header li.w45 {
        min-width: 45%;
        max-width:45%;
    }
    .print-container .header li.h34 {
        height: 34px;
    }

    .print-container .header li.h34_w30 {
        height: 34px;
        width: 30%;
    }

    .print-container .header li.h34_w40 {
        height: 34px;
        width: 40%;
    }

    .mingxi {
        width: 100%;
    }

    .mingxi,
    td {
        border-collapse: collapse;
    }


    .mingxi {
        margin: 0;
    }

    .mingxi td,
    .mingxi th {
        border: 1px solid #000;
    }

    .mingxi td,
    .mingxi th {
        padding: 3px 1px;
        text-align: left;
    }

    .print-center td {
        text-align: center;
    }
    .print-center td:nth-child(1) {
        width: 60px;
    }

    .print-center td:nth-child(2) {
        max-width: 280px;
        text-align: left;

    }
    .print-center td:nth-child(3) {
        width: 90px;
        max-width: 230px;
        text-align: left;
        padding-right: 20px;
        overflow: hidden;
        white-space: nowrap;
    }
    .text-center{
        text-align: center !important;
    }

    .print-center td:last-child {
        width: 150px;
    }
    .f-W600{
        font-weight: 600;
    }

}